<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-md-2 col-lg-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12">
          <div class="card position-relative">
            <button
              class="btn btn-primary btn-add"
              @click="$router.push({ name: 'transfer-create' })"
            >
              <i class="far fa-plus mr-25" /> {{ $t('addTransferToChina') }}
            </button>

            <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
              <div class="anan-tabs__nav">
                <div
                  class="anan-tabs__nav-warp px-2"
                  style="margin-top: 5px;"
                >
                  <div
                    class="anan-tabs__nav-tabs"
                    style="transform: translateX(0px)"
                  >
                    <div
                      class="anan-tabs__nav-tab"
                      :class="{ active: isActive === 'all' }"
                      style="white-space: normal"
                      @click="getData(status = 0,search_val=null),isActive = 'all'"
                    >
                      <span> {{ $t('all') }} </span>
                    </div>

                    <div
                      class="anan-tabs__nav-tab"
                      :class="{ active: isActive === 'cancel' }"
                      style="white-space: normal"
                      @click="getData(status = 1,search_val=null),isActive = 'cancel'"
                    >
                      <span> {{ $t('cancel') }} </span>
                    </div>

                    <div
                      class="anan-tabs__nav-tab"
                      :class="{ active: isActive === 'waiting' }"
                      style="white-space: normal"
                      @click="getData(status = 2,search_val=null),isActive = 'waiting'"
                    >
                      <span> {{ $t('pendingReview') }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center px-2">
              <div>
                <h3 class="font-weight-bolder">
                  {{ $t('transferToChina') }}
                </h3>
              </div>

              <div class="d-flex align-items-center">
                <div class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25">
                  <input
                    v-model="search_val"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @keyup.enter="getData()"
                  >
                  <div class="anan-input__suffix">
                    <button
                      type="button"
                      class="anan-button anan-button--normal anan-button--primary"
                      @click="getData"
                    >
                      <i class="fal fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <b-overlay
              :show="showOver"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>
              <div class="p-2">
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner
                        label="กำลังโหลด..."
                        variant="danger"
                      />
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center">
                      <img
                        src="@/assets/images/anan-img/empty/empty.png"
                        alt="empty"
                        width="180px"
                      >
                      <p>No Data</p>
                    </div>
                  </template>

                  <!-- <template #cell(number)="data">
                  <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                    GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
                  </b-link>
                </template> -->

                  <template #cell(detail)="data">
                    {{ $t('key-55') }} {{ data.item.name_bank }} <br>
                    {{ $t('accountName') }} {{ data.item.name_account }}
                  </template>

                  <template #cell(created_at)="data">
                    {{ time(data.item.created_at) }}
                  </template>
                  <template #cell(date_approve)="data">
                    {{ data.item.date_approve ? time(data.item.date_approve): '-' }}
                  </template>

                  <template #cell(amount)="data">
                    ¥ {{ Commas(data.item.amount) }}
                  </template>

                  <template #cell(fileadmin)="data">
                    <img
                      v-if="data.item.fileadmin"
                      :src="GetImg('transfer_file',data.item.fileadmin)"
                      alt="img-document"
                      class="border"
                      height="40"
                      @click="showPicture(data.item.fileadmin)"
                    >
                    <span v-else>-</span>
                  </template>

                  <template #cell(status)="data">
                    <span
                      v-if="data.item.status === 1"
                      class="badge badge-primary "
                    > {{ $t('pendingReview') }} </span>

                    <span
                      v-if="data.item.status === 2"
                      class="badge badge-success "
                    > {{ $t('operationCompleted') }} </span>
                    <span
                      v-if="data.item.status === 0"
                      class="badge badge-secondary "
                    > {{ $t('cancelled') }} </span>
                  </template>

                  <template #cell(action)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-1 text-primary cursor-pointer"
                      @click="$router.push({ name: 'transfer-edit', params: { id: data.item._id } })"
                    />
                    <h1>{{ data.item.id }}</h1>
                  </template>

                </b-table>

                <div class="demo-spacing-0 d-flex justify-content-end">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="getData(status,search_val)"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import { BOverlay, BPagination, BTable } from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from '../component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    BTable,
    BPagination,
    SideBar,
    // ThisHeader,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: 'all',
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      // items: [],
      status: 0,
      search_val: null,
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'date_approve', label: this.$t('approvalDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'fileadmin', label: this.$t('transactionEvidence'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('status'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData(this.status)
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    async getData(type) {
      this.showOver = true
      try {
        const params = {
          type,
          search_val: this.search_val,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/BankChina/getData', { params })
        // res.data.map(ele => {
        //   ele.fileadmin = null
        // })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
        // this.ShowIMG()
        // console.log(this.items)
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    ShowIMG() {
      const Id = this.items.map(ele => ele._id)

      const params = {
        id: Id,
      }
      this.$http.get('/BankChina/GetDataimg', { params })
        .then(response => {
          response.data.forEach((data, index) => {
            this.items[index].fileadmin = data.fileadmin
          })
        })
        .catch(err => console.log(err))
        // console.debug(params)
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('transfer_file', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
   .anan-set-nav-conttainer span,button,h3{
      font-size: 11px !important;
    }
    .btn-add{
      font-size: 11px !important;
    }
  }
</style>
